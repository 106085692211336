@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{


    .statDisplayerContainer {
        @apply flex flex-col justify-center items-center bg-gray-600 rounded-[1vw] py-[0.3vw] translate-x-[-1px];
    }


    .statDisplayerTopRow {
        @apply font-bold w-full py-[0.3vw] ;
    }

    .leftIdentityComponent {
        /* @apply text-xl mt-[-0.2rem] font-semibold col-start-1 col-span-1 translate-x-4 text-cyan-300; */
        @apply text-[1vw] leading-[1.4vw] mt-[-0.2vw] font-semibold col-start-1 col-span-1 translate-x-4 text-cyan-300;
    }

    .variationContainer {
        @apply flex flex-row justify-center col-start-3 col-span-3;
    }

    .variationHeader {
        /* @apply text-base text-white; */
        @apply text-[0.8vw] leading-[1vw] text-white;
    }

    .variationValue {
        /* @apply text-xl mt-[-3px] ml-[5px] text-orange-300; */
        @apply text-[1vw] leading-[1.4vw] mt-[-0.2vw] ml-[5px] text-orange-300;
    }

    .rightIdentityComponent {
        /* @apply text-xl mt-[-0.2rem] font-semibold translate-x-[-0.8rem] text-cyan-300; */
        @apply text-[1vw] leading-[1.4vw] mt-[-0.2vw] font-semibold translate-x-[-0.8vw] text-cyan-300;
    }



    .statDisplayerBottomRow {
        @apply flex flex-row w-[18.75vw] justify-between px-[1.2vw];
    }

    .one_percent_low_container {
        @apply flex flex-col justify-center items-center font-semibold;
    }

    .one_percent_low_header {
        /* @apply text-base text-white; */
        @apply text-[0.8vw] leading-[1.2vw] text-white;
    }

    .one_percent_low_value {
        /* @apply text-xl mt-[-5px] text-orange-300; */
        @apply text-[1vw] leading-[1.2vw] mt-[-0.1vw] text-orange-300;
    }



    .delayContainer {
        @apply flex flex-col justify-center items-center  font-semibold;
    }

    .delayHeader {
        /* @apply text-base text-white; */
        @apply text-[0.8vw] leading-[1.2vw] text-white;
    }

    .delayValue {
        /* @apply text-xl mt-[-5px] text-orange-300; */
        @apply text-[1vw] leading-[1.2vw] mt-[-0.1vw] text-orange-300;
    }



    .one_percent_high_container {
        @apply flex flex-col justify-center items-center  font-semibold;
    }

    .one_percent_high_header {
        /* @apply text-base text-white; */
        @apply text-[0.8vw] leading-[1.2vw] text-white;
    }

    .one_percent_high_value {
        /* @apply text-xl mt-[-5px] text-orange-300; */
        @apply text-[1vw] leading-[1.2vw] mt-[-0.1vw] text-orange-300;
    }
}