@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .resume {
        z-index: 200;
        width:70%;
        height: auto;
        margin: auto;
        margin-top: 80px;
        margin-bottom: 150px;
    }
}