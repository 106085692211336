@tailwind base;
@tailwind components;
@tailwind utilities;

.partOneTransition-enter {
    opacity: 0;
    transform: translate(-32vw, 0);
}

.partOneTransition-enter-active {
    opacity: 1;
    transform: translate(0vw, 0);
    transition: all 300ms 100ms cubic-bezier(0.8, 0.15, 0.5, 1);


}

.partOneTransition-enter-done {

    /* transform: translate(0vw, 0); */
}

.partOneTransition-exit {
    opacity: 1;
}

.partOneTransition-exit-active {
    opacity: 0;
    transform: translate(-32vw, 0);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}





.partTwoTransition-enter {
    opacity: 0;
    transform: translate(32vw, 0);
    /* transform: translate(48vw, 0); */
}

.partTwoTransition-enter-active {
    opacity: 1;
    transform: translate(0vw, 0);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);


    /* transform: translate(-48vw, 0);
    transition-duration: 300ms;
    transition-timing-function: ease-in-out; */
}

.partTwoTransition-exit {
    opacity: 1;
    /* transform: translate(12vw, 0);
    transition-duration: 300ms; */
}

.partTwoTransition-exit-active {
    opacity: 0;
    transform: translate(32vw, 0);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}







.partTwoSubSectionTransition-enter {
    opacity: 0;
    transform: translate(0, 0);
    /* transform: translate(48vw, 0); */
}

.partTwoSubSectionTransition-enter-active {
    opacity: 1;
    transform: translate(0vw, 4vw);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);


    /* transform: translate(-48vw, 0);
    transition-duration: 300ms;
    transition-timing-function: ease-in-out; */
}

.partTwoSubSectionTransition-enter-done {
    opacity: 1;
    transform: translate(0vw, 4vw);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);


    /* transform: translate(-48vw, 0);
    transition-duration: 300ms;
    transition-timing-function: ease-in-out; */
}

.partTwoSubSectionTransition-exit {
    opacity: 1;
    transform: translate(0vw, 4vw);
}

.partTwoSubSectionTransition-exit-active {
    opacity: 0;
    transform: translate(0, 0);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}

/******************************************************************************************************************************/
/* Shaking animations */
@keyframes shake {
    10%, 90% {
        transform: translate3d(-0.05rem, 0, 0);
    }

    20%, 80% {
        transform: translate3d(0.1rem, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-0.2rem, 0, 0);
    }

    40%, 60% {
        transform: translate3d(0.2rem, 0, 0);
    }
}

.apply-shake {
    animation: shake 0.6s cubic-bezier(.36, .07, .19, .97) both;
}
/******************************************************************************************************************************/

.notesButtonTransition-enter {
    opacity: 0;
    transform: scale(0);
    transform-origin: 1vw 1vw;
    /* transform: translate(48vw, 0); */
}

.notesButtonTransition-enter-active {
    opacity: 1;
    transform: scale(1);
    transform-origin: 1vw 1vw;
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}

.notesButtonTransition-enter-done {
    opacity: 1;
    transform: scale(1);
    transform-origin: 1vw 1vw;
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}

.notesButtonTransition-exit {
    opacity: 1;
    transform: scale(1);
    transform-origin: 1vw 1vw;
}

.notesButtonTransition-exit-active {
    opacity: 0;
    transform: scale(0);
    transform-origin: 1vw 1vw;
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}


.notesTransition-enter {
    opacity: 0;
    transform: scale(0);
    transform-origin: 1vw 1vw;
}

.notesTransition-enter-active {
    opacity: 1;
    transform: scale(1);
    transform-origin: 1vw 1vw;
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);


    /* transform: translate(-48vw, 0);
    transition-duration: 300ms;
    transition-timing-function: ease-in-out; */
}

.notesTransition-enter-done {
    opacity: 1;
    transform: scale(1);
    transform-origin: 1vw 1vw;
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);


    /* transform: translate(-48vw, 0);
    transition-duration: 300ms;
    transition-timing-function: ease-in-out; */
}

.notesTransition-exit {
    opacity: 1;
    transform: scale(1);
    transform-origin: 1vw 1vw;
}

.notesTransition-exit-active {
    opacity: 0;
    transform: scale(0);
    transform-origin: 1vw 1vw;
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}





@layer utilities, components {




    .videoContainer {
        /* aspect-ratio */
        @apply w-3/4;
    }


}