@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    --baseDelay: 60ms;
    --transitionDuration: 100ms;
    --transitionGap: 60ms
}

.drop-down-animation-0-enter{
    opacity: 0;
}
.drop-down-animation-0-enter-active{
    opacity: 1;
    transition:opacity var(--transitionDuration) calc(var(--baseDelay) + var(--transitionGap) * 0) ease-in;
}

.drop-down-animation-0-exit{
    opacity: 1;
}
.drop-down-animation-0-exit-active{
    opacity: 0;
    transition:opacity var(--transitionDuration) calc(var(--baseDelay) + var(--transitionGap) * 0) ease-in;
}

.drop-down-animation-1-enter{
    opacity: 0;
}
.drop-down-animation-1-enter-active{
    opacity: 1;
    transition:opacity var(--transitionDuration) calc(var(--baseDelay) + var(--transitionGap) * 1) ease-in;
}
.drop-down-animation-1-exit{
    opacity: 1;
}
.drop-down-animation-1-exit-active{
    opacity: 0;
    transition:opacity var(--transitionDuration) calc(var(--baseDelay) + var(--transitionGap) * 1) ease-in;
}

.drop-down-animation-2-enter{
    opacity: 0;
}
.drop-down-animation-2-enter-active{
    opacity: 1;
    transition:opacity var(--transitionDuration) calc(var(--baseDelay) + var(--transitionGap) * 2) ease-in;
}
.drop-down-animation-2-exit{
    opacity: 1;
}
.drop-down-animation-2-exit-active{
    opacity: 0;
    transition:opacity var(--transitionDuration) calc(var(--baseDelay) + var(--transitionGap) * 2) ease-in;
}


@layer components{
    
    .NavBarLeftBtnContainer {
        @apply  relative flex flex-row items-start justify-center
        h-fit w-fit my-auto mx-auto

    }

    .NavBarLeftBtn {
        @apply  relative flex 
        h-fit w-fit my-auto mx-auto text-white
        text-[1.2vw] leading-[1vw]

        py-[0.6vw]
        px-[0.8vw]
        rounded-[1vw]

        transition-all duration-200 ease-linear
       hover:text-emerald-400

    }

        

    .DropDownOptionContainer {
        @apply flex flex-col justify-evenly items-start
        absolute 
        w-[200%]
        pt-2
        z-50
        translate-y-[1.8vw]
        translate-x-[24%]
        ;
    }

    .DropDownOption {
        @apply relative flex justify-center items-center bg-emerald-400 rounded-[1vw] w-fit mt-[0.4vw]
        text-[0.9vw] leading-[1vw]
        
        transition-all duration-[600ms] ease-linear
        hover:bg-emerald-600
        
        hover:duration-100
        hover:text-white

        hover:translate-x-[-3px]
        hover:translate-y-[-4px]
       
      
    }

    .DropDownContent{
        @apply  px-[1vw]
        py-[0.2vw]
        ;
    }

    .NavBarRightBtn {
        @apply relative flex items-center justify-center 
        h-[2vw] w-[5vw] my-auto mx-auto bg-white rounded-[1vw]
        text-[1vw] leading-[1.2vw]  font-semibold text-green-800

        transition-all duration-200 ease-linear
        hover:font-bold hover:text-[1.3vw]
        hover:w-[6.6vw] hover:h-[2.6vw]
        hover:text-teal-50 hover:bg-green-700

        ;
    }
  
}

