@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{

    .homePageContentContainer {
        @apply flex flex-col justify-start items-center h-screen w-screen;
    }

    .homePageContent {
        @apply flex flex-col justify-evenly items-center w-screen mt-[5vw];
    }


    /*--------------------------------------- About Me css ---------------------------------------*/
    
    .aboutMe {
        @apply flex flex-row justify-evenly items-center h-[25vw] w-3/4 bg-gray-500 rounded-[1vw]
            bg-opacity-60
        ;
    }


  
    .pfpBorder {
        @apply overflow-hidden h-[15vw] w-[15vw] rounded-full
        shadow-none
        transition-all duration-300 
        /* hover:shadow-[15px_30px_60px_-15px_rgba(0,0,0,1)]
        hover:mb-8 hover:mr-8
        hover:duration-200 */
        ;

    }

    .pfp {
        @apply scale-[1.1] ml-[0.8vw] mt-[1vw]
        transition-all duration-300
        ;
    }


    .descriptionBorder{
        @apply flex flex-col justify-evenly items-center  w-3/5 h-4/5;
    }

    .description{
        @apply flex flex-col justify-evenly items-center
        
        px-2
        py-4
        rounded-[1vw]
        shadow-none
        transition-all duration-300
        /* hover:shadow-[15px_30px_60px_-15px_rgba(0,0,0,1)]
        hover:mb-4 hover:mr-4
        hover:duration-100 */
        ;
    }
}