@tailwind base;
@tailwind components;
@tailwind utilities;


.boringBasicsTransition-enter {
    opacity: 0;
    transform: translate(-32vw, 0);
}

.boringBasicsTransition-enter-active {
    opacity:1;
    transform: translate(0vw, 0);
    transition: all 300ms 100ms cubic-bezier(0.8, 0.15, 0.5, 1);

    
}

.boringBasicsTransition-enter-done {
    
    /* transform: translate(0vw, 0); */
}

.boringBasicsTransition-exit {
    opacity: 1;
}

.boringBasicsTransition-exit-active {
    opacity: 0;
    transform: translate(-32vw, 0);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
}





.encounteredProblemsTransition-enter {
    opacity: 0;
    transform: translate(32vw, 0);
    /* transform: translate(48vw, 0); */
}

.encounteredProblemsTransition-enter-active {
    opacity:1;
    transform: translate(0vw, 0);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);

    
    /* transform: translate(-48vw, 0);
    transition-duration: 300ms;
    transition-timing-function: ease-in-out; */
}

.encounteredProblemsTransition-exit {
    opacity: 1;
    /* transform: translate(12vw, 0);
    transition-duration: 300ms; */
}

.encounteredProblemsTransition-exit-active {
    opacity: 0;
    transform: translate(32vw, 0);
    transition: all 300ms 0ms cubic-bezier(0.8, 0.15, 0.5, 1);
    
}


/******************************************************************************************************************************/
/* Shaking animations */
@keyframes boringBasicShake {
    /* original translation (-18.85vw, 0.7vw) */

    15%, 65% {
        /* -0.35 */
        transform: translate3d(-19.2vw, 0.7vw, 0);
    }

    40%, 90% {
        /* +0.35 */
        transform: translate3d(-18.5vw, 0.7vw, 0);
    }
}

.apply-boringBasics-shake {
    animation: boringBasicShake 0.15s cubic-bezier(.36, .07, .19, .97) both;
}

@keyframes encounteredProblemsShake {
    /* original translation (13.25vw, 0.7vw) */

    15%, 65% {
        /* -0.35 */
        transform: translate3d(12.9vw, 0.7vw, 0);
    }

    40%, 90% {
        /* +0.35 */
        transform: translate3d(13.6vw, 0.7vw, 0);
    }
}

.apply-encounteredProblems-shake {
    animation: encounteredProblemsShake 0.15s cubic-bezier(.36, .07, .19, .97) both;
}

/******************************************************************************************************************************/


@layer components{









    /* .boringBasics {
        @apply absolute;
    } */


    
    /* .encounteredProblems {
        @apply absolute;
    } */

}